<form class="form-container" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div>
    @if (resetWithCode) {
      <app-custom-input
        [formControlName]="'code'"
        [type]="'text'"
        [id]="'confirmation-code'"
        [label]="LABEL_CONSTANT.CODE"
        [icon]="'user-input-icon.svg'"
        [required]="true"
      />
    }
    <app-custom-input
      [formControlName]="'password'"
      [type]="'password'"
      [id]="'password'"
      [label]="LABEL_CONSTANT.PASSWORD"
      [icon]="'lock.svg'"
      [showHide]="true"
      [required]="true"
    />
    <app-custom-input
      [formControlName]="'confirmPassword'"
      [type]="'password'"
      [id]="'confirm-password'"
      [label]="LABEL_CONSTANT.CONFIRM_PASSWORD"
      [icon]="'lock.svg'"
      [showHide]="true"
      [required]="true"
    />
    <control-messages [control]="resetPasswordForm" />
  </div>
  <app-button
    [type]="'submit'"
    [className]="'primary-form-button'"
    [label]="resetWithCode ? LABEL_CONSTANT.CHANGE : LABEL_CONSTANT.UPDATE"
  />
  <span class="nav-link" (click)="switchToLoginFlow()"> {{ LABEL_CONSTANT.LOGIN }} </span>
</form>
