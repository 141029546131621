<app-card>
  <form class="form-container" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
    <div>
      <app-custom-input
        [formControl]="signInForm.controls.userName"
        [type]="'text'"
        [id]="'username'"
        [label]="LABEL_CONSTANT.USERNAME_EMAIL"
        [icon]="'user-input-icon.svg'"
        [placeholder]="PLACEHOLDER_CONSTANT.EMAIL_FORMAT"
        [required]="true"
      />
    </div>
    <app-button
      [type]="'submit'"
      [className]="'primary-form-button'"
      [label]="LABEL_CONSTANT.NEXT"
    />
  </form>
</app-card>
