import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LABEL_CONSTANT, ARIA_CONSTANT} from '../../constant/constant';
import {Router, RoutesRecognized} from '@angular/router';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  policyDomain = environment.docsUrl;
  LABEL_CONSTANT = LABEL_CONSTANT;
  ARIA_CONSTANT = ARIA_CONSTANT;

  public isFooter = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.isFooter = !!data.state.root.firstChild?.data['footer'];
      }
    });
  }
}
