<form class="form-container" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
  <div>
    <app-custom-input
      [formControlName]="'userName'"
      [type]="'text'"
      [id]="'username'"
      [label]="LABEL_CONSTANT.USERNAME_EMAIL"
      [icon]="'user-input-icon.svg'"
      [placeholder]="PLACEHOLDER_CONSTANT.EMAIL_FORMAT"
      [required]="true"
    />
    <app-custom-input
      [formControlName]="'password'"
      [type]="'password'"
      [id]="'password'"
      [label]="LABEL_CONSTANT.PASSWORD"
      [icon]="'lock.svg'"
      [showHide]="true"
      [required]="true"
    />
  </div>
  <app-button
    [type]="'submit'"
    [className]="'primary-form-button'"
    [label]="LABEL_CONSTANT.LOGIN"
  />
  <span class="nav-link" (click)="switchToForgotPasswordFlow()"> Forgot password </span>
</form>
