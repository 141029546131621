@if (label) {
  <div class="custom-label">
    <label for="{{ id }}">
      {{ label }}
    </label>
  </div>
}
<div class="input-container">
  <input
    [ngClass]="{
      'input-box': true,
      'image-included': !!icon,
      'hide-icon-included': showHide,
      'input-error': ngControl.control?.dirty && !ngControl.control?.valid
    }"
    [formControl]="ngControl.control | formControl"
    [type]="type"
    [id]="id"
    [placeholder]="placeholder"
    [required]="required"
  />

  @if (!!icon) {
    <div class="image-container">
      <img ngSrc="{{ '../../../assets/images/' + icon }}" width="25" height="25" aria-hidden="true" alt=""/>
    </div>
  }
  @if(isPasswordValidator && ngControl.invalid) {
    <div class="password-validation-container">
      <div class="tip left">
        <app-password-requirements [control]="ngControl.control | formControl"/>
      </div>
    </div>
  }
  @if (!!showHide) {
    <div class="image-container hide-icon-container" (click)="showHideText()">
      @if (this.type === 'password') {
        <fa-icon [icon]="faEyeSlash"></fa-icon>
      } @else {
        <fa-icon [icon]="faEye"></fa-icon>
      }
    </div>
  }
</div>
<control-messages [control]="ngControl.control | formControl" [label]="label!.toLocaleLowerCase()"></control-messages>
