import {Component} from '@angular/core';
import {LogoContainerComponent} from '../logo-container/logo-container.component';
import LABEL_CONSTANT from '../../constant/constant';

@Component({
  selector: 'app-loader',
  standalone: true,
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  imports: [LogoContainerComponent],
})
export class LoaderComponent {
  LABEL_CONSTANT = LABEL_CONSTANT;
}
