import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {faCircleCheck, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import LABEL_CONSTANT, {PASSWORD_REQUIREMENTS} from '../../constant/constant';
import {ValidationService} from '../../shared/services/validation.service';

@Component({
  selector: 'app-password-requirements',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './password-requirements.component.html',
  styleUrl: './password-requirements.component.scss',
})
export class PasswordRequirementsComponent implements OnDestroy {
  LABEL_CONSTANT = LABEL_CONSTANT;
  public passwordRequirements = [
    PASSWORD_REQUIREMENTS.ONE_LOWERCASE,
    PASSWORD_REQUIREMENTS.ONE_UPPERCASE,
    PASSWORD_REQUIREMENTS.ONE_NUMBER,
    PASSWORD_REQUIREMENTS.MINIMUM_LENGTH,
    PASSWORD_REQUIREMENTS.ONE_SPECIAL_CHARACTER,
  ];
  errors: ValidationErrors = {};
  private sub: Subscription | undefined;
  @Input() set control(con: AbstractControl<any, any>) {
    con.addValidators([
      ValidationService.minLengthValidator(),
      ValidationService.oneLowerCaseValidator(),
      ValidationService.oneUpperCaseValidator(),
      ValidationService.oneNumberValidator(),
      ValidationService.oneSpecialCharacterValidator(),
    ]);
    this.sub = con.valueChanges.subscribe(() => {
      this.errors = con.errors as ValidationErrors;
    });
    con.updateValueAndValidity();
  }
  faCircleCheck = faCircleCheck;
  faCircleXmark= faCircleXmark;

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
