import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {LoadingService} from '../services/loading.service';
import {finalize} from 'rxjs';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService);
  loadingService.setLoading(true, req.url);
  return next(req).pipe(finalize(() => loadingService.setLoading(false, req.url)));
};
