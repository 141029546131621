import {Component, OnDestroy, SecurityContext, signal, WritableSignal} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {marked} from 'marked';

import {CardComponent} from '../../components/card/card.component';
import {LABEL_CONSTANT, PAGE_TITLE_CONSTANT, ROUTE_CONSTANT} from '../../constant/constant';
import {
  DomainOrganizationService,
  IOrganization,
} from '../../core/services/domain-organization.service';
import {SystemAccessNotificationService} from '../../core/services/system-access-notification.service';
import {CognitoService} from '../../core/auth/cognito.service';
import {HttpErrorService} from '../../core/services/http-error.service';
import {CognitoOrgIdService} from '../../core/auth/cognito-org-id.service';
import {AppAuthorizationService} from '../../core/auth/app-authorization.service';
import {SignInComponent} from './sign-in/sign-in.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {MfaSetupComponent} from './mfa-setup/mfa-setup.component';
import {MfaPromptComponent} from './mfa-prompt/mfa-prompt.component';

export const SIGN_IN_STATE = {
  SIGN_IN: 'SIGN_IN',
  CONFIRM_SIGN_IN: 'CONFIRM_SIGN_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  MFA_SETUP: 'MFA_SETUP',
  MFA_PROMPT: 'MFA_PROMPT',
};

@Component({
  selector: 'app-organization-login',
  standalone: true,
  templateUrl: './organization-login.component.html',
  styleUrl: './organization-login.component.scss',
  imports: [
    CardComponent,
    SignInComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    MfaSetupComponent,
    MfaPromptComponent,
  ],
})
export class OrganizationLoginComponent implements OnDestroy {
  LABEL_CONSTANT = LABEL_CONSTANT;
  SIGN_IN_STATE = SIGN_IN_STATE;
  markdown?: string;
  userName: string = '';
  clientId: string = '';
  private readonly orgId: string;
  private signInState: WritableSignal<string> = signal('');
  private resetFlowUsername: WritableSignal<string> = signal('');
  signInOutput: any | null = null;
  constructor(
    private router: Router,
    private activatedRoutes: ActivatedRoute,
    private _domainOrganizationService: DomainOrganizationService,
    private _systemAccessNotificationService: SystemAccessNotificationService,
    private sanitizer: DomSanitizer,
    private _cognitoService: CognitoService,
    private _cognitoOrgIdService: CognitoOrgIdService,
    private _httpErrorService: HttpErrorService,
    private title: Title,
    private _appAuthorizationService: AppAuthorizationService,
  ) {
    this.orgId = this.activatedRoutes.snapshot.params['orgId'];
    this._cognitoOrgIdService.orgId.set(this.orgId);
  }

  ngOnInit(): void {
    this.fetchUserNameFromURL();

    this._systemAccessNotificationService
      .getSystemAccessNotification()
      .subscribe((notes: string) => {
        this.markdown = this.sanitizer.sanitize(SecurityContext.HTML, marked(notes)) as string;
      });
  }

  public setResetFlowUsername(username: string): void {
    this.resetFlowUsername.set(username);
  }

  public getResetFlowUsername(): string {
    return this.resetFlowUsername();
  }

  public setSignInState(state: string): void {
    this.signInState.set(state);
  }

  public getSignInState(): string {
    return this.signInState();
  }

  public flowLabel(signInState: string): string {
    switch (signInState) {
      case SIGN_IN_STATE.FORGOT_PASSWORD:
        return LABEL_CONSTANT.FORGOT_PASSWORD;
      case SIGN_IN_STATE.RESET_PASSWORD:
        return LABEL_CONSTANT.CHANGE_PASSWORD;
      case SIGN_IN_STATE.MFA_SETUP:
        return LABEL_CONSTANT.SETUP_MFA;
      case SIGN_IN_STATE.MFA_PROMPT:
        return LABEL_CONSTANT.TWO_FACTOR_AUTHENTICATION;
      default:
        return '';
    }
  }

  public routeToApplicationGettingDefaultOrgDetails(): void {
    const orgDetails = this._cognitoService.getOrgDetails();
    if (orgDetails.defaultAppClientId && orgDetails.userPoolId) {
      this.clientId = this.clientId || orgDetails.defaultAppClientId;
      this.routeToApplicationIfAuthenticated(orgDetails);
    } else {
      void this.getDefaultOrgDetails();
    }
  }

  private fetchUserNameFromURL(): void {
    this.activatedRoutes.queryParams.subscribe((params) => {
      this.userName = params?.['login_hint'];
      this.clientId = params?.['client_id'];
      this.setSignInState(SIGN_IN_STATE.SIGN_IN);
      this.routeToApplicationGettingDefaultOrgDetails();
    });
  }

  private async getDefaultOrgDetails(): Promise<void> {
    const orgDetails = await lastValueFrom(
      this._domainOrganizationService.getOrgDetails(this.orgId),
    );
    this.title.setTitle(`${PAGE_TITLE_CONSTANT.LOGIN} | ${orgDetails.name}`);
    this.clientId = this.clientId || orgDetails?.defaultAppClientId;
    if (!orgDetails?.userPoolId || !orgDetails?.defaultAppClientId) {
      void this.router.navigate([ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND]);
    } else {
      this.routeToApplicationIfAuthenticated(orgDetails);
    }
  }

  private routeToApplicationIfAuthenticated(orgDetails: IOrganization): void {
    this._cognitoService.setOrgDetails(orgDetails, this.clientId);
    this._cognitoService.isAuthenticated().then((auth: boolean) => {
      if (auth) {
        void this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
      }
    });
  }

  ngOnDestroy(): void {
    this._httpErrorService.httpError.set('');
  }
}
