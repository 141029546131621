<div class="password-requirement-container">
  <h2 class="password-requirement-header">
    {{ LABEL_CONSTANT.PASSWORD_REQUIREMENTS }}
  </h2>
  <div class="long-diving-line"></div>
  @for (item of passwordRequirements; track $index) {
    <div class="condition-container">
      @if (errors[item.rule]) {
        <span class="xmark">
          <fa-icon [icon]="faCircleXmark" width="18" height="18" />
        </span>
      } @else {
        <span class="check">
          <fa-icon [icon]="faCircleCheck" width="18" height="18" />
        </span>
      }
      <label class="condition">
        {{ item.label }}
      </label>
    </div>
  }
</div>