<form class="form-container" [formGroup]="mfaSetupForm" (ngSubmit)="onVerify()">
    <div class="setup-instructions">
      <div class="left-column">
        <div class="setup-step step-one">
          <h3>{{ MFA_SETUP_CONSTANT.STEP_ONE }}</h3>
          <p>{{ MFA_SETUP_CONSTANT.STEP_ONE_DESCRIPTION }}</p>
          <ul>
            <li>{{ MFA_SETUP_CONSTANT.GOOGLE_AUTHENTICATOR }}</li>
            <li>{{ MFA_SETUP_CONSTANT.MICROSOFT_AUTHENTICATOR }}</li>
            <li>{{ MFA_SETUP_CONSTANT.AUTHY }}</li>
          </ul>

          <p>{{ MFA_SETUP_CONSTANT.TO_GET_STARTED }}</p>
          <ul>
            <li>{{ MFA_SETUP_CONSTANT.VISIT_APP_STORE }}</li>
            <li>{{ MFA_SETUP_CONSTANT.SEARCH }}</li>
            <li>{{ MFA_SETUP_CONSTANT.DOWNLOAD }}</li>
            <li>{{ MFA_SETUP_CONSTANT.OPEN }}</li>
            <li>{{ MFA_SETUP_CONSTANT.CHOOSE }}</li>
          </ul>

          <div class="note">
            <p>{{ MFA_SETUP_CONSTANT.NOTE }}</p>
          </div>
        </div>
      </div>

      <div class="right-column">
          <div class="setup-step">
            @if(qrCodeUrl) {
            <h3>{{ MFA_SETUP_CONSTANT.STEP_TWO }}</h3>
              <div class="qr-container">
                <qrcode
                  [qrdata]="qrCodeUrl.href"
                  [width]="150"
                  [errorCorrectionLevel]="'M'"
                  [ariaLabel]="ARIA_CONSTANT.QR_CODE"
                />
              </div>
            } @else {
              <h3>{{ VALIDATION_ERROR_CONSTANT.QR_NOT_SHOWN }}</h3>
            }
          </div>

        <div class="setup-step step-three">
          <h3>{{ MFA_SETUP_CONSTANT.STEP_THREE }}</h3>
          <app-custom-input
            [formControlName]="'verificationCode'"
            [type]="'text'"
            [id]="'verification-code'"
            [label]="MFA_SETUP_CONSTANT.ENTER_CODE"
            [icon]="'lock.svg'"
            [placeholder]="PLACEHOLDER_CONSTANT.SIX_DIGIT_CODE"
            [required]="true"/>
        </div>
      </div>
    </div>
  <app-button
    [type]="'submit'"
    [className]="'primary-form-button'"
    [label]="isVerifying ? LABEL_CONSTANT.VERIFYING : LABEL_CONSTANT.SETUP"/>
</form>
